import { AssessmentEvidence } from '../../../../models/guideline';
import React, { useContext, useMemo } from 'react';
import {
  FailedBadge,
  PassedBadge,
  TestParamsRenderer,
  TestResultContent,
  formatTestTitleFromID,
} from '../TestResultContent';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  Flex,
  Button,
} from '@chakra-ui/react';
import _ from 'lodash';
import { MetricContent } from '../MetricContent';

import { ProjectContext, UsersContext } from '../../../../contexts';
import { canUpdateMetadata } from '../../../../auth/utils';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
interface RiskAssessmentItemEvidenceItemProps {
  evidence: AssessmentEvidence;
}

export default function RiskAssessmentItemEvidenceItem({
  evidence,
}: RiskAssessmentItemEvidenceItemProps) {
  const { project } = useContext(ProjectContext);
  const { currentUser } = useContext(UsersContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Validators should be able to edit validator evidence
  // when they belong to this model
  const hasValidatorEditPerms = useMemo(
    () =>
      currentUser
        ? canUpdateMetadata(
            currentUser,
            project?.inventory_model.users!,
            ModelDocumentTypeEnum.validation_report,
          )
        : false,
    [currentUser, project?.inventory_model.users],
  );

  // Only allow editing validator evidence when the current user is a validator for the model
  // and the evidence content type is ModelDocumentTypeEnum.validation_report
  const evidenceContentType =
    evidence.metric_result?.content_type || evidence.test_result?.content_type;
  const canEditValidatorEvidence =
    hasValidatorEditPerms &&
    evidenceContentType === ModelDocumentTypeEnum.validation_report;
  const readOnly = !canEditValidatorEvidence;

  if (evidence.evidence_type === 'TestResult') {
    return (
      <React.Fragment key={evidence.cuid}>
        {evidence.test_result && (
          <AccordionItem
            data-testid="developer-evidence-accordion-item"
            border={'1px solid'}
            borderColor={useColorModeValue(
              'neutral.200 !important',
              'neutral.800 !important',
            )}
            mb={1}
            borderRadius={'md'}
            overflow={'hidden'}
          >
            <AccordionButton
              _hover={{ bg: useColorModeValue('neutral.50', 'neutral.850') }}
            >
              <HStack w={'full'} justifyContent={'space-between'}>
                <Text>{_.startCase(`${evidence.test_result.test_name}`)}</Text>
                <Flex pr={4}>
                  {evidence.test_result.passed ? (
                    <PassedBadge />
                  ) : (
                    <FailedBadge />
                  )}
                </Flex>
              </HStack>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              {evidence.test_result?.params !== null &&
                evidence.test_result?.params !== undefined && (
                  <TestParamsRenderer params={evidence.test_result.params} />
                )}
              <Box w={'full'} textAlign={'right'}>
                <Button
                  onClick={onOpen}
                  alignSelf={'flex-end'}
                  variant={'ghost'}
                >
                  See evidence details
                </Button>
              </Box>

              <Modal isCentered isOpen={isOpen} onClose={onClose} size={'6xl'}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    <HStack>
                      <Text>
                        {formatTestTitleFromID(evidence.test_result?.test_name)}
                      </Text>
                      {evidence.test_result?.passed ? (
                        <PassedBadge size="md" />
                      ) : (
                        <FailedBadge size="md" />
                      )}
                    </HStack>
                  </ModalHeader>
                  <ModalBody>
                    <TestResultContent
                      contents={{
                        content_id: evidence.test_result?.test_name,
                        content_type: evidence.test_result?.content_type,
                      }}
                      readOnly={readOnly}
                      hideHeader
                      overrideDocumentType={evidence.test_result.content_type}
                      documentType={evidence.test_result.content_type}
                    />
                  </ModalBody>
                  <ModalCloseButton />
                </ModalContent>
              </Modal>
            </AccordionPanel>
          </AccordionItem>
        )}
      </React.Fragment>
    );
  } else if (evidence.evidence_type === 'MetricResult') {
    return (
      <React.Fragment key={evidence.cuid}>
        {evidence.metric_result && (
          <AccordionItem
            border={'1px solid'}
            borderColor={useColorModeValue(
              'neutral.200 !important',
              'neutral.800 !important',
            )}
            mb={1}
            borderRadius={'md'}
            overflow={'hidden'}
          >
            <AccordionButton
              _hover={{ bg: useColorModeValue('neutral.50', 'neutral.850') }}
            >
              <HStack w={'full'} justifyContent={'space-between'}>
                <Text>{_.startCase(`${evidence.metric_result.key}`)}</Text>
              </HStack>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel pb={4}>
              <MetricContent
                contents={{
                  content_id: evidence.metric_result?.key,
                  content_type: evidence.metric_result?.content_type,
                }}
                readOnly={readOnly}
                hideHeader
                overrideDocumentType={evidence.metric_result.content_type}
                documentType={evidence.metric_result.content_type}
              />
            </AccordionPanel>
          </AccordionItem>
        )}
      </React.Fragment>
    );
  }
  return <></>;
}
