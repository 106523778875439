import { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectContext } from '../../../../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Accordion,
  Box,
  Button,
  HStack,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AssessmentOption, Guideline } from '../../../../models/guideline';
import useValidationGuideline from '../../../../hooks/useValidationGuideline';
import { useMutation, useQueryClient } from 'react-query';
import API from '../../../../api/API';
import { LoadingContainer } from '../../../LoadingContainer';
import { Label } from '../../../Layout';
import { ChevronDownIcon, Icon } from '@chakra-ui/icons';
import { TProject } from '../../../../models';
import RiskAssessmentNotes from './RiskAssessmentNotes';
import RiskAssessmentItemEvidenceItem from './RiskAssessmentItemEvidenceItem';
import RiskAssessmentAddEvidenceModal from './RiskAssessmentAddEvidenceModal';
import { ArrowUturnLeftIcon, LinkIcon } from '@heroicons/react/24/outline';
import RiskAssessmentAddFindingModal from './RiskAssessmentAddFindingModal';
import FindingRow from '../../../FindingsList/FindingRow';
import ConfirmationAlert from '../../../ConfirmationAlert';
import { ModelDocumentTypeEnum } from '../../../../models/model_document';
import DocumentationContext from '../../../../contexts/DocumentationContext';
import { EmptyStateDisplay } from '../../../EmptyStateDisplay';

interface PostValidationGuidelineAssessmentVars {
  project: TProject;
  content_id: string;
  assessmentOption: AssessmentOption | null;
}

interface PostEvidenceVars {
  project: TProject;
  content_id: string;
  testContentIds: string[];
  metricContentIds: string[];
  selectedDocumentType: ModelDocumentTypeEnum;
}

interface PostFindingVars {
  project: TProject;
  content_id: string;
  findingCuids: string[];
}

interface GuidelineItemProps {
  content_id: string;
  readOnlyFields: boolean;
}

export default function GuidelineItem({
  content_id,
  readOnlyFields,
}: GuidelineItemProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();
  const { project } = useContext(ProjectContext);
  const { hasValidationReportResults } = useContext(DocumentationContext);

  const [guideline, setGuideline] = useState<Guideline>();
  const [assessmentOption, setAssessmentOption] = useState<AssessmentOption>();
  const [showAddEvidenceModal, setShowAddEvidenceModal] = useState(false);
  const [evidenceModalDocumentType, setEvidenceModalDocumentType] =
    useState<ModelDocumentTypeEnum>(ModelDocumentTypeEnum.model_documentation);
  const [showAddFindingModal, setShowAddFindingModal] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);

  const { data, isLoading, refetch } = useValidationGuideline({
    project: project!,
    content_id,
  });

  useEffect(() => {
    setGuideline(data);
  }, [data]);

  useEffect(() => {
    if (guideline && guideline.assessment) {
      setAssessmentOption(guideline.assessment.option);
    }
  }, [guideline]);

  const postAssessment = useMutation(
    [],
    async ({
      project,
      content_id,
      assessmentOption,
    }: PostValidationGuidelineAssessmentVars) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PostValidationGuidelineAssessment(
        accessToken,
        project,
        content_id,
        assessmentOption ? assessmentOption.cuid : '',
      );
    },
    {
      onSuccess: assessment => {
        setGuideline({ ...guideline!, assessment: assessment });
        toast({
          title: 'Assessment saved.',
          description: 'Your assessment has been saved.',
          status: 'success',
          duration: 3000,
          variant: 'subtle',
          isClosable: true,
          position: 'bottom-right',
        });
        queryClient.invalidateQueries({
          queryKey: [
            'project',
            project?.inventory_model.cuid!,
            'risk-assessment-summary',
          ],
        });
      },
      onError: err => {
        toast({
          title: 'An error occurred.',
          description: API.getAPIErrorMessage(err),
          status: 'error',
          variant: 'subtle',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const postEvidences = useMutation(
    [],
    async ({
      project,
      content_id,
      testContentIds,
      metricContentIds,
      selectedDocumentType,
    }: PostEvidenceVars) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PatchValidationGuidelineAssessmentEvidences(
        accessToken,
        project,
        content_id,
        testContentIds,
        metricContentIds,
        selectedDocumentType,
      );
    },
    {
      onError: err => {
        toast({
          title: 'An error occurred.',
          description: API.getAPIErrorMessage(err),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
      onSuccess: data => {
        refetch();
      },
    },
  );

  const postFindings = useMutation(
    [],
    async ({ project, content_id, findingCuids }: PostFindingVars) => {
      const accessToken = await getAccessTokenSilently();
      return await API.PatchValidationGuidelineAssessmentFindings(
        accessToken,
        project,
        content_id,
        findingCuids,
      );
    },
    {
      onError: err => {
        toast({
          title: 'An error occurred.',
          description: API.getAPIErrorMessage(err),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
      onSuccess: data => {
        refetch();
      },
    },
  );

  const onResetAssessment = () => {
    setAssessmentOption(undefined);

    postAssessment.mutate({
      project: project!,
      content_id,
      assessmentOption: null,
    });
  };

  const onResetConfirmed = (confirmed: boolean) => {
    setResetRequested(false);
    if (confirmed) {
      onResetAssessment();
    }
  };

  const onSelectAssessment = (cuid: string) => {
    const option = guideline?.options?.find(option => option.cuid === cuid);
    setAssessmentOption(option!);

    postAssessment.mutate({
      project: project!,
      content_id,
      assessmentOption: option!,
    });
  };

  const onSelectedEvidences = (
    testContentIds: string[],
    metricContentIds: string[],
    selectedDocumentType: ModelDocumentTypeEnum,
  ) => {
    postEvidences.mutate({
      project: project!,
      content_id,
      testContentIds,
      metricContentIds,
      selectedDocumentType,
    });
  };

  const onSelectedFinding = (findingCuids: string[]) => {
    postFindings.mutate({
      project: project!,
      content_id,
      findingCuids,
    });
  };

  const { developerEvidences, validatorEvidences } = useMemo(() => {
    const developerEvidences =
      guideline?.assessment?.evidences?.filter(e => {
        if (e.evidence_type === 'TestResult') {
          return e.test_result!.content_type === 'model_documentation';
        } else if (e.evidence_type === 'MetricResult') {
          return e.metric_result!.content_type === 'model_documentation';
        }
      }) || [];

    const validatorEvidences =
      guideline?.assessment?.evidences?.filter(e => {
        if (e.evidence_type === 'TestResult') {
          return e.test_result!.content_type === 'validation_report';
        } else if (e.evidence_type === 'MetricResult') {
          return e.metric_result!.content_type === 'validation_report';
        }
      }) || [];

    return { developerEvidences, validatorEvidences };
  }, [guideline?.assessment?.evidences]);

  return (
    <LoadingContainer isLoading={isLoading}>
      {!data && (
        <EmptyStateDisplay variant="no-activity">
          <Heading as={'h5'}>
            This Guideline (<code>{content_id}</code>) has been deleted.
          </Heading>
          <Text align={'center'}>
            Please remove this guideline from your Validation Report template.
          </Text>
        </EmptyStateDisplay>
      )}
      {!!data && (
        <Box mb={12}>
          <Heading as={'h3'} mb={4} id={`h-${content_id}`}>
            {data?.title}
          </Heading>

          <Stack spacing={8}>
            <Stack
              rounded={'md'}
              bg={useColorModeValue('white', 'neutral.900')}
              border={'1px solid'}
              borderTopColor={useColorModeValue('neutral.200', 'neutral.700')}
              borderBottomColor={useColorModeValue(
                'neutral.200',
                'neutral.700',
              )}
              borderRightColor={useColorModeValue('neutral.200', 'neutral.700')}
              borderLeftWidth={4}
              borderLeftColor={assessmentOption?.style.bg || 'neutral.200'}
              p={[4, 5]}
            >
              <Text fontWeight={'bold'}>Guideline</Text>
              <Text
                dangerouslySetInnerHTML={{
                  __html: guideline?.description as string,
                }}
              ></Text>
              <Label>Assessment</Label>

              <Box>
                <Menu autoSelect={false} matchWidth gutter={0}>
                  {({ isOpen }) => (
                    <>
                      <HStack>
                        <MenuButton
                          isDisabled={readOnlyFields}
                          data-testid="assessment-option-menu-btn"
                          as={Button}
                          rightIcon={<ChevronDownIcon w={5} h={5} />}
                          borderWidth={1}
                          borderRadius="md"
                          w="full"
                          textAlign="left"
                          fontWeight={'normal'}
                        >
                          {assessmentOption ? (
                            <Text>{assessmentOption.name}</Text>
                          ) : (
                            <Text>Select a value for this guideline</Text>
                          )}
                        </MenuButton>
                        {assessmentOption && (
                          <>
                            <ConfirmationAlert
                              open={resetRequested}
                              title={`Reset guideline assessment`}
                              dialogBody={`Are you sure you'd like to reset this guideline's assessment?`}
                              cancelButton={'No, cancel'}
                              confirmButton={'Yes, reset'}
                              onConfirm={onResetConfirmed}
                            />
                            <Button
                              isDisabled={readOnlyFields}
                              variant="ghost"
                              onClick={() => setResetRequested(true)}
                              data-testid="add-finding-btn"
                              leftIcon={
                                <Icon as={ArrowUturnLeftIcon} boxSize={5} />
                              }
                              bg={'transparent'}
                              color={useColorModeValue(
                                'neutral.500',
                                'neutral.100',
                              )}
                              _hover={useColorModeValue(
                                { bg: 'neutral.100' },
                                { bg: 'neutral.800' },
                              )}
                            >
                              Reset
                            </Button>
                          </>
                        )}
                      </HStack>
                      <MenuList w={'full'} padding={0} boxShadow={'xl'}>
                        <MenuOptionGroup
                          onChange={cuid => {
                            onSelectAssessment(cuid as string);
                          }}
                          defaultValue={assessmentOption?.cuid}
                          value={assessmentOption?.cuid}
                          type="radio"
                        >
                          {guideline?.options
                            ?.sort((a, b) => a.order - b.order)
                            .map(option => (
                              <MenuItemOption
                                key={option.cuid}
                                value={option.cuid}
                                borderLeftColor={
                                  option?.style.bg || 'neutral.500'
                                }
                                borderLeftWidth={4}
                                py={2}
                              >
                                <Text>{option.name}</Text>
                                <Text fontSize={'sm'}>
                                  {option.description}
                                </Text>
                              </MenuItemOption>
                            ))}
                        </MenuOptionGroup>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
            </Stack>
            <Stack px={6} spacing={8}>
              {guideline?.require_notes && (
                <RiskAssessmentNotes
                  guideline={guideline}
                  readOnlyFields={readOnlyFields}
                />
              )}

              <Stack>
                <HStack>
                  <Heading as={'h4'}>Developer Evidence</Heading>
                  <Spacer />
                  <Button
                    isDisabled={readOnlyFields}
                    variant={'ghost'}
                    onClick={() => {
                      setEvidenceModalDocumentType(
                        ModelDocumentTypeEnum.model_documentation,
                      );
                      setShowAddEvidenceModal(true);
                    }}
                    data-testid="add-finding-btn"
                    leftIcon={<Icon as={LinkIcon} boxSize={5} />}
                  >
                    Link Evidence to Report
                  </Button>
                </HStack>
                {developerEvidences.length > 0 ? (
                  <Accordion allowToggle>
                    {developerEvidences.map(evidence => (
                      <RiskAssessmentItemEvidenceItem
                        key={evidence.cuid}
                        evidence={evidence}
                      />
                    ))}
                  </Accordion>
                ) : (
                  <></>
                )}
              </Stack>

              {hasValidationReportResults && (
                <Stack>
                  <HStack>
                    <Heading as={'h4'}>Validator Evidence</Heading>
                    <Spacer />
                    <Button
                      isDisabled={readOnlyFields}
                      variant={'ghost'}
                      onClick={() => {
                        setEvidenceModalDocumentType(
                          ModelDocumentTypeEnum.validation_report,
                        );
                        setShowAddEvidenceModal(true);
                      }}
                      data-testid="add-finding-btn"
                      leftIcon={<Icon as={LinkIcon} boxSize={5} />}
                    >
                      Link Evidence to Report
                    </Button>
                  </HStack>
                  {validatorEvidences.length > 0 ? (
                    <Accordion allowToggle>
                      {validatorEvidences.map(evidence => (
                        <RiskAssessmentItemEvidenceItem
                          key={evidence.cuid}
                          evidence={evidence}
                        />
                      ))}
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </Stack>
              )}

              {!hasValidationReportResults && (
                <Stack>
                  <Heading as={'h4'}>Validator Evidence</Heading>
                  <Text mt={2} fontStyle={'oblique'} color={'neutral.500'}>
                    It looks like you don't have any test results logged as a
                    Validator.{' '}
                    <Link
                      color="brand.500"
                      href={`/model-inventory/${project?.inventory_model.cuid}/getting-started`}
                    >
                      Click here to get started.
                    </Link>
                  </Text>
                </Stack>
              )}

              <Stack>
                <HStack>
                  <Heading as={'h4'}>Findings</Heading>
                  <Spacer />
                  <Button
                    isDisabled={readOnlyFields}
                    variant="ghost"
                    onClick={() => setShowAddFindingModal(true)}
                    data-testid="add-finding-btn"
                    leftIcon={<Icon as={LinkIcon} boxSize={5} />}
                  >
                    Link Finding to Report
                  </Button>
                </HStack>

                {(guideline?.assessment?.findings || []).length > 0 ? (
                  <Accordion allowToggle>
                    <Stack spacing={1}>
                      {guideline?.assessment?.findings?.map(finding => (
                        <FindingRow
                          key={finding.cuid}
                          finding={finding.finding}
                        />
                      ))}
                    </Stack>
                  </Accordion>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Stack>

          <RiskAssessmentAddEvidenceModal
            isOpen={showAddEvidenceModal}
            onClose={() => setShowAddEvidenceModal(false)}
            onSelectedEvidence={onSelectedEvidences}
            testResultContentIds={guideline?.assessment?.evidences
              ?.filter(
                e =>
                  e.evidence_type === 'TestResult' &&
                  e.evidence_content_type === evidenceModalDocumentType,
              )
              .map(e => e.evidence_content_id)}
            metricResultContentIds={guideline?.assessment?.evidences
              ?.filter(
                e =>
                  e.evidence_type === 'MetricResult' &&
                  e.evidence_content_type === evidenceModalDocumentType,
              )
              .map(e => e.evidence_content_id)}
            documentType={evidenceModalDocumentType}
          />

          <RiskAssessmentAddFindingModal
            isOpen={showAddFindingModal}
            onClose={() => setShowAddFindingModal(false)}
            onSelectedFinding={onSelectedFinding}
            findingCuids={guideline?.assessment?.findings?.map(
              f => f.finding.cuid,
            )}
          />
        </Box>
      )}
    </LoadingContainer>
  );
}
