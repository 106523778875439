import {
  Icon,
  LightMode,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  useColorMode,
} from '@chakra-ui/react';
import { TRole } from '../../models';
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { getRoleUIProps } from '../../utils';

interface RolePillProps {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onAdd?: React.MouseEventHandler<HTMLButtonElement>;
  role: TRole;
}

export default function RolePill({ role, onClose, onAdd }: RolePillProps) {
  const roleUIProps = getRoleUIProps(role.name);
  const roleIcon = roleUIProps.icon;
  const colorScheme = roleUIProps.colorScheme;
  const { colorMode } = useColorMode();

  return (
    <LightMode>
      <Tag
        variant="role-pill"
        bg={colorMode === 'light' ? colorScheme + '.200' : colorScheme + '.900'}
        data-testid="role-pill"
        px={2}
        py={0}
        minH={7}
        _hover={
          onAdd || onClose
            ? {
                bg:
                  colorMode === 'light'
                    ? colorScheme + '.300'
                    : colorScheme + '.700',
              }
            : {}
        }
        transition={'background-color 0.2s ease-in-out'}
      >
        {<TagLeftIcon as={roleIcon || UserIcon} boxSize={5} />}
        <TagLabel p={0} m={0} pointerEvents={'none'}>
          {role.name}
        </TagLabel>
        {onClose && <TagCloseButton onClick={onClose} />}
        {onAdd && (
          <TagCloseButton onClick={onAdd}>
            <Icon as={PlusIcon} />
          </TagCloseButton>
        )}
      </Tag>
    </LightMode>
  );
}
