import { useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { BreadcrumbSeparator } from '@chakra-ui/react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { ProjectContext } from '../../contexts';

interface IBreadcrumbsProps {}

const routes = [
  { path: '/model-inventory/:id', breadcrumb: 'Project details' },
  {
    path: '/model-inventory/:id/findings/:finding_cuid',
    breadcrumb: 'Finding Details',
  },
];

export default function Breadcrumbs({}: IBreadcrumbsProps) {
  const { project } = useContext(ProjectContext);
  const routerBreadcrumbs = useBreadcrumbs(routes);

  // Filter out the first 3 routes in the breadcrumb array:
  // Home (/), Models (/model-inventory) and Model Details (/model-inventory/:id)
  // Replace the projects route with "Model Inventory" route
  // This is to simplify the breadcrumb navigation according to the current
  // dashboard section: Documents, Findings, etc.
  const filteredBreadcrumbs = routerBreadcrumbs
    .filter(
      breadcrumb =>
        breadcrumb.match.pattern.path !==
          '/model-inventory/:id/findings/:finding_cuid/actions' &&
        breadcrumb.match.pattern.path !== '/',
    )
    .map(breadcrumb => {
      if (breadcrumb.key === '/projects') {
        breadcrumb.breadcrumb = 'Model Inventory';
        breadcrumb.match.pathname = '/model-inventory';
      } else if (breadcrumb.key.match(/\/model-inventory\/[a-zA-Z0-9]+$/)) {
        breadcrumb.breadcrumb = project?.inventory_model.name;
        breadcrumb.match.pathname = `/model-inventory/${project?.inventory_model.cuid}/overview`;
      } else if (breadcrumb.key.endsWith('/findings')) {
        breadcrumb.match.pathname = `/model-inventory/${project?.inventory_model.cuid}/findings`;
      }

      return breadcrumb;
    });

  return (
    <Breadcrumb color="neutral.500">
      {filteredBreadcrumbs
        .slice(0, filteredBreadcrumbs.length - 1)
        .map(({ match, breadcrumb }, index) => (
          <BreadcrumbItem key={`bc-${index}`} ml={0}>
            <BreadcrumbLink
              key={match.pathname}
              as={ReactLink}
              to={match.pathname}
              textTransform="capitalize"
            >
              {match.route?.path === '/model-inventory/:id'
                ? project?.inventory_model.name
                : breadcrumb}
            </BreadcrumbLink>
            {index == filteredBreadcrumbs.length - 1 && <BreadcrumbSeparator />}
          </BreadcrumbItem>
        ))}
      <BreadcrumbSeparator />
    </Breadcrumb>
  );
}
