import {
  Box,
  Center,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  VStack,
  Button,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import API from '../../../../api/API';
import { ProjectContext } from '../../../../contexts';
import { useAuth0 } from '@auth0/auth0-react';
import AddFindingModal from '../../../AddFindingModal';
import FindingRow from '../../../FindingsList/FindingRow';

interface RiskAssessmentAddFindingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectedFinding: (findings: string[]) => void;
  findingCuids?: string[];
}
export default function RiskAssessmentAddFindingModal({
  isOpen,
  onClose,
  onSelectedFinding,
  findingCuids,
}: RiskAssessmentAddFindingModalProps) {
  const { project } = useContext(ProjectContext);
  const { getAccessTokenSilently } = useAuth0();
  const [selected, setSelected] = useState(new Set());

  useEffect(() => {
    if (findingCuids) {
      setSelected(new Set(findingCuids));
    }
  }, [findingCuids]);

  const {
    data: findings,
    isLoading,
    refetch: refetchFindings,
  } = useQuery(
    ['project', project?.cuid, 'findings'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const filters = {
        projects: [project?.cuid!],
      };
      return await API.GetFindings(accessToken, 1, 100, filters);
    },
    {
      onSuccess: data => console.log(data),
    },
  );

  const toggleCheck = (testName: string) => {
    const updatedSet = new Set(selected);

    if (updatedSet.has(testName)) {
      updatedSet.delete(testName);
    } else {
      updatedSet.add(testName);
    }

    setSelected(updatedSet);
  };

  const onInsert = () => {
    onSelectedFinding(Array.from(selected) as string[]);
    onClose();
  };

  return (
    <Modal isCentered size={'6xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Link Finding to Report</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={1}>
            {findings?.results.length === 0 && (
              <Center fontStyle={'italic'} minH={40}>
                This model does not appear to have any findings.
              </Center>
            )}
            {findings?.results.map(finding => (
              <Checkbox
                isChecked={selected.has(finding?.cuid)}
                onChange={e => toggleCheck(finding?.cuid as string)}
                w={'full'}
                className="link-finding-checkbox"
                role="group"
              >
                <Box
                  pointerEvents={'none'}
                  w={'full'}
                  _groupHover={{ opacity: 0.7 }}
                >
                  <FindingRow finding={finding} />
                </Box>
              </Checkbox>
            ))}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <AddFindingModal
            onFindingAdded={() => refetchFindings()}
            triggerLabel={'Create New Finding'}
          />
          <Spacer />

          <Button
            isDisabled={findings?.results.length === 0}
            onClick={onInsert}
          >
            Update Linked Findings
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
