import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import API from '../../api/API';
import { ContentPageH2 } from '../Layout';
import {
  Text,
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  useColorModeValue,
  Tooltip,
  HStack,
  StackDivider,
  IconButton,
  useToken,
  Heading,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  Label,
} from 'recharts';
import ChartTooltip from '../ChartTooltip';
import { displayFormatedDateAndTime } from '../../utils';
import { TrashIcon } from '@heroicons/react/24/outline';
import { SectionContentsProps } from '../Layout/DocumentationPage';
import { useContext, useEffect, useRef, useState } from 'react';
import { ProjectContext } from '../../contexts';
import ConfirmationAlert from '../ConfirmationAlert';
import { EmptyStateDisplay } from '../EmptyStateDisplay';
import _ from 'lodash';
import { useInView } from 'react-intersection-observer';
import { LoadingContainer } from '../LoadingContainer';
import { TUnitMetric } from '../../models/unit_metric';
import AvatarProxy from '../AvatarProxy';

export default function UnitMetricContent({
  contents,
  removeBlock,
  readOnly,
}: SectionContentsProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { content_id, options = {} } = contents;
  const { project } = useContext(ProjectContext);
  const [isFocused, setIsFocused] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const unitMetricContentRef = useRef(null);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { data, isLoading, isError } = useQuery(
    ['unit-metrics', content_id],
    async () => {
      const accessToken = await getAccessTokenSilently();

      const response = await API.GetUnitMetricsValuesForKey(
        accessToken,
        project!.inventory_model.cuid,
        content_id,
      );

      return response.results.map((result: TUnitMetric) => {
        // Round the value to 4 decimal places
        result.value = Math.round(result.value * 10000) / 10000;
        return result;
      });
    },
    {
      enabled: !!project?.inventory_model.cuid && inView,
      retry: false,
    },
  );

  const onDeleteConfirmed = (confirmed: boolean) => {
    if (confirmed && removeBlock) {
      setIsFocused(false);
      removeBlock(contents);
    }
    setConfirmDelete(false);
  };

  const handleClickOutside = (e: any) => {
    if (
      unitMetricContentRef.current &&
      !(unitMetricContentRef.current as any).contains(e.target)
    ) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const [brandColor] = useToken('colors', ['brand.base']);

  if (!isLoading && (isError || data?.length === 0)) {
    return (
      <>
        <ContentPageH2>
          {<Text>{_.startCase(content_id.split('.').pop())}</Text>}
        </ContentPageH2>
        <EmptyStateDisplay variant="no-activity">
          <Heading as={'h5'}>
            The data associated with this Metric Over Time Block (
            {<code>{content_id}</code>}) is missing.
          </Heading>
          <Text align={'center'}>
            To populate this content block please make sure to run the
            associated documentation test suite.
          </Text>
          <Text align={'center'} pb={10}>
            Please refer to{' '}
            <a href="https://docs.validmind.ai" target="_blank">
              ValidMind's documentation
            </a>{' '}
            for more information.
          </Text>
        </EmptyStateDisplay>
      </>
    );
  }

  return (
    <LoadingContainer isLoading={isLoading}>
      <ConfirmationAlert
        open={confirmDelete}
        title={`Remove "${data && data.length && data[0].name}" Block`}
        dialogBody={`Are you sure you'd like to remove "${
          data && data.length && data[0].name
        }" block?`}
        onConfirm={onDeleteConfirmed}
      />
      <Box ref={ref} position={'relative'} flex={1} display="flex">
        {readOnly !== true && (
          <Box
            position={'absolute'}
            hidden={!isFocused}
            top={'-42px'}
            left={'0'}
            boxShadow={'var(--ck-drop-shadow),0 0'}
            w={'min'}
            rounded={'md'}
          >
            <HStack
              divider={
                <StackDivider borderColor="neutral.200" m={'1 !important'} />
              }
              rounded={'md'}
              bg={'white'}
              border={'1px solid var(--chakra-colors-neutral-200)'}
              gap={0}
            >
              <Tooltip
                label="Remove Metric Over Time Block"
                hasArrow
                placement="bottom"
                bg={useColorModeValue('neutral.200', 'neutral.700')}
                boxShadow={'none'}
                color={useColorModeValue('neutral.800', 'neutral.200')}
                openDelay={500}
                arrowSize={5}
              >
                <IconButton
                  aria-label="Remove Metric Over Time Block"
                  bg={'white !important'}
                  color={'neutral.800 !important'}
                  _hover={{
                    color: 'red.600  !important',
                    bg: 'red.100 !important',
                  }}
                  icon={<TrashIcon width={20} height={20} />}
                  onClick={() => setConfirmDelete(true)}
                />
              </Tooltip>
            </HStack>
          </Box>
        )}

        <Tooltip
          gutter={0}
          bg={useColorModeValue('neutral.200', 'neutral.700')}
          boxShadow={'none'}
          color={useColorModeValue('neutral.800', 'neutral.200')}
          placement="top-end"
          isDisabled={isFocused}
          label="Click to edit this block"
          openDelay={1000}
          offset={[1, 0]}
          fontSize="md"
          hidden={readOnly === true}
        >
          <VStack
            p={2}
            border={'1px solid'}
            borderColor={isFocused ? 'brand.base' : 'transparent'}
            bg={
              isFocused
                ? useColorModeValue('neutral.50', 'neutral.850')
                : 'transparent'
            }
            borderRadius={'md'}
            transition={'all .5s ease-in-out'}
            _hover={
              isFocused
                ? {
                    borderColor: 'brand.base',
                  }
                : {
                    borderColor: useColorModeValue(
                      'neutral.200',
                      'neutral.700',
                    ),
                  }
            }
            onClick={() => {
              if (readOnly) return;
              setIsFocused(true);
            }}
            maxH="500px"
            height="500px"
            overflow="hidden"
            w="full"
            display="flex"
            alignItems="flex-start"
            ref={unitMetricContentRef}
          >
            <ContentPageH2>
              {data && data.length && <Text>{data[0].name}</Text>}
            </ContentPageH2>
            <VStack
              alignItems="flex-start"
              flex={1}
              flexGrow={1}
              w="full"
              overflow="hidden"
            >
              <Tabs
                isFitted
                overflowY="auto"
                display="flex"
                flexDirection="column"
                w="full"
                h="full"
                colorScheme="brand"
              >
                <TabList flex={0}>
                  <Tab flex={0}>Chart</Tab>
                  <Tab flex={0}>Data</Tab>
                </TabList>
                <TabPanels w="full" h="full" overflowY="auto" display="flex">
                  <TabPanel padding={0} paddingTop={4} w="full" h="full">
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis
                          tickCount={5}
                          label={{
                            dx: -10,
                            angle: -90,
                            style: {
                              fontSize: '14px',
                              fontWeight: 'normal',
                            },
                          }}
                          style={{
                            fontSize: '14px',
                            fontWeight: 'normal',
                          }}
                          axisLine={false}
                          tickLine={false}
                          domain={[
                            (dataMin: number) => dataMin * 0.9,
                            (dataMax: number) => dataMax * 1.1,
                          ]}
                          tickFormatter={v => v.toFixed(2)}
                        >
                          <Label
                            style={{
                              textAnchor: 'middle',
                            }}
                            angle={270}
                            value={data && data.length ? data[0].name : ''}
                            dx={-25}
                          />
                        </YAxis>
                        <XAxis
                          dataKey="recorded_at"
                          tickFormatter={v => displayFormatedDateAndTime(v)}
                          display="none"
                        >
                          <Label
                            style={{
                              textAnchor: 'middle',
                            }}
                            value={'Recorded At (Time)'}
                          />
                        </XAxis>
                        <RechartsTooltip
                          content={({ payload, ...params }) => {
                            if (!payload || payload.length === 0) {
                              return null;
                            }
                            const currentPayload = payload[0];
                            const payloadParams =
                              currentPayload.payload.params || {};
                            let extraContent = null;

                            const paramKeys = Object.keys(payloadParams || {});

                            if (paramKeys.length > 0) {
                              extraContent = (
                                <VStack w={'full'}>
                                  <Text fontWeight="bold">Params</Text>
                                  {
                                    <VStack alignItems="flex-start">
                                      {paramKeys.map((key, i) => (
                                        <HStack key={i}>
                                          <Text>{key}:</Text>
                                          <Text>{payloadParams[key]}</Text>
                                        </HStack>
                                      ))}
                                    </VStack>
                                  }
                                </VStack>
                              );
                            }
                            return (
                              <ChartTooltip
                                {...params}
                                payload={payload}
                                showValue={true}
                                labelFormatter={(v: any) =>
                                  displayFormatedDateAndTime(v)
                                }
                                extraContent={extraContent}
                              />
                            );
                          }}
                          wrapperStyle={{
                            fontSize: '14px',
                            fontWeight: 'normal',
                            color: 'black',
                            zIndex: 1000,
                          }}
                          labelFormatter={v => displayFormatedDateAndTime(v)}
                        />
                        <Line
                          type="monotone"
                          dataKey="value"
                          fill={brandColor}
                          stroke={brandColor}
                          activeDot={{ r: 6 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </TabPanel>
                  <TabPanel
                    padding={0}
                    paddingTop={4}
                    w="full"
                    h="full"
                    overflowY="auto"
                  >
                    <Box overflowY="scroll" height="100%" w="full">
                      <Table w="full">
                        <Thead>
                          <Tr>
                            <Th>Recorded At</Th>
                            <Th>Updated By</Th>
                            <Th>Value</Th>
                            <Th>Params</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {data &&
                            data?.map((item: TUnitMetric, i) => (
                              <Tr key={i}>
                                <Td>
                                  <Text fontSize="small">
                                    {displayFormatedDateAndTime(
                                      item.recorded_at,
                                    )}
                                  </Text>
                                </Td>
                                <Td>
                                  {item.user && (
                                    <Tag
                                      key={`data-${i}-user-${item.user}`}
                                      size={'md'}
                                      borderRadius="full"
                                    >
                                      <AvatarProxy
                                        src={item.user.picture}
                                        size="xs"
                                        name={item.user.name}
                                        ml={-2}
                                        mr={2}
                                      />
                                      <TagLabel>{item.user.name}</TagLabel>
                                    </Tag>
                                  )}
                                </Td>
                                <Td fontSize="small">
                                  <Text>{item.value}</Text>
                                </Td>
                                <Td fontSize="small">
                                  <Text>
                                    {item.params &&
                                      Object.keys(item.params).map((key, i) => (
                                        <Text key={i}>
                                          {key}: {item.params[key]}
                                        </Text>
                                      ))}
                                  </Text>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </VStack>
        </Tooltip>
      </Box>
    </LoadingContainer>
  );
}
