import {
  Box,
  Divider,
  Heading,
  SimpleGrid,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  VStack,
} from '@chakra-ui/react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

const StyleGuideTag = ({
  colors,
  sizes,
}: {
  colors: string[];
  sizes: string[];
}) => {
  const variants = ['solid', 'outline', 'subtle'];
  sizes = sizes.filter(s => s !== 'xs');
  return (
    <Box>
      <Heading as={'h2'}>Tag</Heading>
      <Divider />
      <Heading as={'h3'}>Sizes</Heading>
      <SimpleGrid columns={[1, 2]} spacing={4}>
        {variants.map(variant => (
          <VStack key={variant} gap={1} alignItems={'flex-start'}>
            {sizes.map(size => (
              <Tag variant={variant} colorScheme="brand" mb={1} size={size}>
                <TagLeftIcon as={Cog6ToothIcon} />
                <TagLabel>
                  {variant} {size}
                </TagLabel>
                <TagRightIcon as={Cog6ToothIcon} />
                <TagCloseButton />
              </Tag>
            ))}
          </VStack>
        ))}
      </SimpleGrid>
      <Divider />
      <Heading as={'h3'}>Colors</Heading>
      <SimpleGrid columns={[1, 2]} spacing={4}>
        {colors.map(color => (
          <VStack key={color} gap={1} alignItems={'flex-start'}>
            <Heading as="h3" size="sm">
              {color}
            </Heading>
            <Tag variant="outline" colorScheme={color} mb={1}>
              Outline {color}
            </Tag>
            <Tag variant="solid" colorScheme={color} mb={1}>
              Solid {color}
            </Tag>
            <Tag variant="subtle" colorScheme={color} mb={1}>
              Subtle {color}
            </Tag>
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default StyleGuideTag;
