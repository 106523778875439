import { Handle, NodeProps, Position } from 'reactflow';
import {
  CircularProgress,
  HStack,
  Icon,
  Stack,
  Text,
  Button,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { generateRandomCodeForNodes } from '../../../../../../utils';
import API from '../../../../../../api/API';
import NodeBox from '../../layouts/NodeBox';
import StatusBadge from '../../../../../../components/StatusBadge';
import { SignalIcon } from '@heroicons/react/20/solid';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { StatusSetNodeType } from '../../types';

function StatusSetNode(props: NodeProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { setSelectedNodeId } = useWorkflow();

  const statusCuid = props.data.state_callbacks.on_enter[0].args.cuid;
  const statusEntity = props.data.state_callbacks.on_enter[0].args.entity;

  if (!statusCuid || !statusEntity) {
    return (
      <NodeBox
        title={'STATUS CHANGE'}
        icon={SignalIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <HStack px={2} pb={2} w={'full'}>
          <Button
            leftIcon={<Icon as={Cog6ToothIcon} boxSize={4} />}
            onClick={() => setSelectedNodeId!(props.id)}
            w={'full'}
          >
            Configure
          </Button>
        </HStack>
      </NodeBox>
    );
  }

  const { data: status, isLoading } = useQuery(
    ['statuses', statusCuid],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetStatusesWorkflowStatus(accessToken, statusCuid);
    },
  );

  if (isLoading) {
    return (
      <NodeBox
        title={'STATUS CHANGE'}
        icon={SignalIcon}
        bg={useColorModeValue('neutral.50', 'neutral.800')}
        border={2}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        borderStyle={'solid'}
        nodeProps={props}
        rounded={'lg'}
      >
        <Stack justify={'center'} align={'center'}>
          <CircularProgress
            size="40px"
            thickness="2px"
            isIndeterminate
            color="brand.base"
          />
          <Text fontSize={'sm'} color={'inherit'}>
            Please hold...
          </Text>
        </Stack>
      </NodeBox>
    );
  }

  const DEFAULT_HANDLE_STYLES = {
    background: useColorModeValue(
      'var(--chakra-colors-neutral-200)',
      'var(--chakra-colors-neutral-800)',
    ),
    borderColor: useColorModeValue(
      'var(--chakra-colors-neutral-50)',
      'var(--chakra-colors-neutral-500)',
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
    height: 16,
    width: 16,
  };
  return (
    <NodeBox
      title={'STATUS CHANGE'}
      icon={SignalIcon}
      bg={useColorModeValue('neutral.50', 'neutral.800')}
      border={2}
      borderColor={useColorModeValue('neutral.200', 'neutral.700')}
      borderStyle={'solid'}
      nodeProps={props}
      rounded={'lg'}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...DEFAULT_HANDLE_STYLES, top: -8 }}
      />
      <HStack alignItems={'flex-start'} px={4} pb={4}>
        <VStack gap={0} alignItems={'flex-start'} pt={1}>
          <VStack w={'full'} alignItems={'start'} gap={0} rounded={'md'}>
            <Text fontSize={'xs'}>
              {/* TODO: support validation report */}
              Set {statusEntity === 'InventoryModel'
                ? 'Model'
                : 'Document'}{' '}
              Status to{' '}
            </Text>
            <StatusBadge
              bg={status!.colors.primary}
              color={status!.colors.tertiary}
            >
              {status!.name}
            </StatusBadge>
          </VStack>
        </VStack>
      </HStack>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...DEFAULT_HANDLE_STYLES, bottom: -8 }}
      />
    </NodeBox>
  );
}

StatusSetNode.type = 'status_set';
StatusSetNode.autoRuns = true;

StatusSetNode.getDefaultNode = (): StatusSetNodeType => {
  return {
    id: `${StatusSetNode.type}_${generateRandomCodeForNodes()}`,
    type: StatusSetNode.type,
    data: {
      state_callbacks: {
        on_enter: [
          {
            func: 'run_save_status',
            args: { entity: null, cuid: null },
          },
        ],
        on_exit: [],
      },
      transition_callbacks: {
        conditions: [],
        prepare: [],
        before: [],
        after: [],
      },
    },
    position: { x: 0, y: 0 },
  };
};

export default StatusSetNode;
