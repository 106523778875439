import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../api/API';
import { TProject, TProjectFilters } from '../../models/project';
import { keyable } from '../../models/utils';
import { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';

type UseProjectsProps = {
  filters?: keyable;
  enabled?: boolean;
};

function useCurrentDocument(project: TProject, documentTypeParam?: string) {
  const { getAccessTokenSilently } = useAuth0();
  const queryResponse = useQuery(
    ['inventory-model', project.inventory_model.cuid, documentTypeParam],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetDocument(accessToken, project, documentTypeParam);
    },
    {
      staleTime: 1000 * 60 * 10,
    },
  );
  return queryResponse;
}

function useCurrentProject() {
  // Always scope the metric to a project identifier
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const toast = useToast();

  const queryResponse = useQuery(
    ['project', id],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return API.GetProjectByModelId(accessToken, id!);
    },
    {
      staleTime: 1000 * 60 * 10,
      onError: (error: AxiosError) => {
        if (error.response?.status === 404) {
          navigate('/model-inventory', { replace: true });
          toast({
            title: 'Model not found',
            status: 'error',
            isClosable: true,
          });
        }
      },
    },
  );

  return { project: queryResponse.data, ...queryResponse };
}

function useProjects({ filters, enabled = true }: UseProjectsProps = {}) {
  const { getAccessTokenSilently } = useAuth0();

  const queryResponse = useQuery(
    ['project', JSON.stringify(filters || {})],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetProjects(accessToken, filters as TProjectFilters);
    },
    {
      enabled,
    },
  );

  return { projectResults: queryResponse.data, ...queryResponse };
}

export { useCurrentProject, useProjects, useCurrentDocument };
