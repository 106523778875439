import {
  Button,
  Icon,
  Link,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import AttributesRail from '../Layout/AttributesRail';
import { TProject } from '../../models';
import { Label } from '../Layout';
import { useNavigate } from 'react-router-dom';
import { InfoIcon } from '@chakra-ui/icons';

interface Props {
  project: TProject;
  onOpenTemplateModal?: () => void;
}

export default function AttributesRailMonitoringOverview({
  project,
  onOpenTemplateModal,
}: Props) {
  const navigate = useNavigate();
  return (
    <AttributesRail>
      <VStack alignItems={'flex-start'} gap={1}>
        <Icon
          as={InfoIcon}
          color={useColorModeValue('neutral.500', 'neutral.200')}
          mt={1}
        />
        <Text>
          Learn how to add ongoing monitoring data to your template. <br />
          <Link
            onClick={() => {
              navigate(
                `/model-inventory/${project?.inventory_model.cuid}/getting-started`,
              );
            }}
          >
            Getting Started
          </Link>
        </Text>
      </VStack>
      {onOpenTemplateModal && (
        <VStack align="start">
          <Label>ONGOING MONITORING TEMPLATE</Label>
          <Button
            variant={'link'}
            onClick={onOpenTemplateModal}
            whiteSpace={'normal'}
            textAlign={'left'}
            data-testid="view-monitoring-template-btn"
          >{`${project?.template_monitoring.name} v${project?.template_monitoring.version_number}`}</Button>
        </VStack>
      )}
    </AttributesRail>
  );
}
