import {
  Box,
  Flex,
  Icon,
  Text,
  HStack,
  Square,
  VStack,
  LinkBox,
  useColorModeValue,
} from '@chakra-ui/react';
import { TProject } from '../../models';
import { displayRelativeDate } from '../../utils';
import { Link as RouterLink } from 'react-router-dom';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import StatusBadge from '../StatusBadge';
import { Label } from '../Layout';
interface ProjectListItemProps {
  project: TProject;
}
export function ProjectListItem({ project }: ProjectListItemProps) {
  return (
    <LinkBox
      key={project.cuid}
      as={RouterLink}
      to={`/model-inventory/${project.inventory_model.cuid}`}
      data-testid="project-list-item"
    >
      <Flex
        pl={6}
        pr={8}
        py={6}
        gap={8}
        role="group"
        border={'1px solid'}
        borderColor={useColorModeValue('neutral.200', 'neutral.700')}
        align={'stretch'}
        _hover={{
          bg: useColorModeValue('neutral.100', 'neutral.800'),
          color: useColorModeValue('inherit', 'neutral.200'),
        }}
        transition={'all .2s ease-in-out'}
        borderRadius={'md'}
        h={'full'}
      >
        <HStack w={'full'} height={'full'} alignItems={'flex-start'} gap={4}>
          <Square alignSelf={'flex-start'}>
            <Icon
              boxSize={10}
              color={useColorModeValue('neutral.400', 'neutral.700')}
              _groupHover={{ color: 'neutral.400' }}
              as={BookOpenIcon}
            />
          </Square>
          <VStack
            height={'full'}
            width={'full'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            ml={'0 !important'}
          >
            <VStack alignItems={'flex-start'} gap={0}>
              <Text
                fontSize={'lg'}
                fontWeight={'semibold'}
                color={useColorModeValue('neutral.600', 'neutral.300')}
                _groupHover={{
                  color: useColorModeValue('neutral.800', 'neutral.200'),
                }}
              >
                {project.name}
              </Text>
              <Text
                fontSize={'sm'}
                color={useColorModeValue('neutral.600', 'neutral.400')}
              >
                Last updated{' '}
                {displayRelativeDate(project.updated_at || project.created_at)}
              </Text>
            </VStack>
          </VStack>
          <Box mt={1} minW={'fit-content'}>
            <Label mb={2}>DOCUMENT STATUS</Label>
            <StatusBadge
              color={
                project.status ? project.status.colors.tertiary : 'default'
              }
              bg={project.status ? project.status.colors.primary : 'default'}
            >
              {project.status ? project.status.name : 'No status'}
            </StatusBadge>
          </Box>
        </HStack>
      </Flex>
    </LinkBox>
  );
}
