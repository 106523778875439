import { useAuth0 } from '@auth0/auth0-react';
import {
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Text,
  Textarea,
  useToast,
  VStack,
  Button,
} from '@chakra-ui/react';
import React, { MutableRefObject, useContext, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import API, { TComment } from '../../api/API';
import { displayRelativeDate } from '../../utils';
import { ContentPageH2 } from '../Layout';
import { StatusChangedToast } from '../Layout/Toasts';
import UsersContext from '../../contexts/UsersContext';
import { ProjectContext } from '../../contexts';
import AvatarProxy from '../AvatarProxy';

interface ICommentsFooterProps {
  itemType: string;
  itemCuid: string;
  allowAdd?: boolean;
}

export default function CommentsFooter({
  itemType,
  itemCuid,
  allowAdd = true,
}: ICommentsFooterProps) {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { project } = useContext(ProjectContext);
  const { currentUser } = useContext(UsersContext);
  const toast = useToast();

  const [commentActionsVisible, setCommentActionsVisible] = useState(false);
  const [commentText, setCommentText] = useState('');
  const commentActionsRef = useRef(
    null,
  ) as unknown as MutableRefObject<HTMLDivElement>;

  const scrollToBottom = () => {
    commentActionsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const { data: comments, refetch: refetchComments } = useQuery(
    ['project', id, itemType, itemCuid, 'comments'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetCommentsForProject(project!, accessToken, itemCuid);
    },
  );

  const addCommentMutation = useMutation(
    ['project', id, itemType, itemCuid, 'add-comment'],
    async (text: string) => {
      const accessToken = await getAccessTokenSilently();

      return API.PostCommentForProject(
        project!,
        accessToken,
        text,
        '',
        itemType,
        itemCuid,
      );
    },
    {
      onSuccess: (newComment: TComment) => {
        refetchComments();
        setCommentText('');
        setCommentActionsVisible(false);
        scrollToBottom();
      },
      onError: error => {
        if (error instanceof Error) {
          // TODO: Track
          toast({
            position: 'bottom-right',
            duration: 3000,
            isClosable: true,
            render: () => (
              <StatusChangedToast
                message="Could not add comment"
                error={error.message}
                status="error"
              />
            ),
          });
        }
      },
    },
  );

  return (
    <VStack gap={2} alignItems={'flex-start'}>
      <ContentPageH2 mb={2}>Comments</ContentPageH2>
      <Flex
        direction="column"
        gap={2}
        ml={2}
        hidden={comments && comments?.length < 1}
      >
        {comments?.map((comment, index) => (
          <React.Fragment key={`comment-block-${index}`}>
            <VStack key={`comment-${index}`} align="flex-start" p={2} gap={2}>
              <HStack
                gap={1}
                justifyItems={'flex-start'}
                alignItems={'flex-start'}
              >
                <AvatarProxy
                  name={comment?.user.name}
                  src={comment?.user.picture}
                  size={'xs'}
                  mr={2}
                  ignoreFallback
                />

                <VStack justify="center" align="flex-start" spacing={0}>
                  <Text
                    fontWeight="semi-bold"
                    fontSize="sm"
                    color="neutral.600"
                  >
                    <strong>{comment?.user.name} </strong>

                    {displayRelativeDate(comment.created_at)}
                  </Text>
                  <Text>{comment?.title}</Text>
                </VStack>
              </HStack>
            </VStack>
            <Divider key={`divider-${index}`} borderColor="neutral.200" />
          </React.Fragment>
        ))}
      </Flex>
      <Flex w="100%" gap={2} hidden={!allowAdd}>
        {currentUser && (
          <AvatarProxy
            name={currentUser?.name}
            src={currentUser?.picture}
            size={'sm'}
          />
        )}
        <VStack align="flex-end" w={'full'}>
          <Textarea
            bg="white"
            placeholder="Write a comment"
            value={commentText}
            onChange={e => setCommentText(e.target.value)}
            onFocus={() => setCommentActionsVisible(true)}
            onClick={scrollToBottom}
          />
          <ButtonGroup
            hidden={!commentActionsVisible}
            size="xs"
            display="flex"
            justifyContent="flex-end"
          >
            <Button onClick={() => setCommentActionsVisible(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => addCommentMutation.mutate(commentText)}
              disabled={commentText.length < 1 || addCommentMutation.isLoading}
              isLoading={addCommentMutation.isLoading}
            >
              Submit
            </Button>
          </ButtonGroup>
        </VStack>
      </Flex>
      <Flex ref={commentActionsRef}></Flex>
    </VStack>
  );
}
